import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderImage from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import CallToAction from '@interness/theme-twentytwenty/src/components/CallToAction/CallToAction';
export const query = graphql`
    query {
        media: directusMediaCollection(name: {eq: "headers"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
  <Img fluid={props.data.media.media[0].file.localFile.childImageSharp.fluid} mdxType="Img" />
    </HeaderImage>
    <Wrapper style={{
      textAlign: 'justify'
    }} width={960} mdxType="Wrapper">
      <hr></hr>
  <Heading mdxType="Heading">Über uns</Heading>
      <p>{`  Ihr kompetenter Juwelier für Trauringe, Schmuck, Verlobungsringe, Antragsringe, Brautschmuck, Uhren, Goldankauf sowie
Schmuck- und Uhrreparaturen.
Seit 110 Jahren, mittlerweile schon in der dritten Generation, sind wir für Sie da.
Gerne möchten wir Sie in eine Welt der schönen Dinge, der Inspiration, Stimmungen, Gefühle und Lebensfreude entführen.
Begegnen Sie auserwählt edlen Schmuckstücken in meisterhafter Ausführung und erstklassiger Qualität. Den Trends der
Saison mit klassischen aber auch außergewöhnlichen Glanzstücken der Goldschmiedekunst.
Geschulte Mitarbeiter aus unserem Hause unterstützen Sie bei Ihren individuellen Vorstellungen mit freundlicher
Beratung und fundiertem Fachwissen.
Mit zahlreichen Service-Angeboten sind wir selbstverständlich auch nach dem Kauf für Sie da.
In unserem Trauringstudio bieten eine einzigartige Vielfalt & Qualität mit top Beratung zu fairen Preisen.
Wir freuen uns auf Ihren Besuch in unserem Juwelier-Fachgeschäft.`}</p>
      <p>{`  Ihr Juwelier Uhrmacher- und Augenoptiker Meister`}</p>
      <hr></hr>
    </Wrapper>
    <CallToAction mdxType="CallToAction" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      